import * as React from 'react';
import { Typography, Grid, TextField, Alert, Link } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import emailjs from '@emailjs/browser';
import { phoneNumber, phoneNumberViewable } from '../util/Services';

const Inquiry: React.FC = () => {
  const service = 'service_66e1dxg';
  const template = 'template_37jil3j';
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [nameFilled, setNameFilled] = useState<boolean>(true);
  const [emailFilled, setEmailFilled] = useState<boolean>(true);
  const [messageFilled, setMessageFilled] = useState<boolean>(true);
  const [submitAllowed, setSubmitAllowed] = useState<boolean>(false);
  const [sendAttemps, setSendAttemps] = useState<number>(0);
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const [sendFail, setSendFail] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  useEffect(() => {
      setSubmitAllowed(!!(name && email && message));
      if (name != undefined && name != '') {
        setNameFilled(true);
      }
      if (email != undefined && email != '') {
        setEmailFilled(true);
      } 
      if (message != undefined && message != '') {
        setMessageFilled(true);
      }
  }, [name, email, message]);
  useEffect(() => {
    if (sendSuccess) {
      // Show success
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setTimeout(() => {
        setMessageFilled(true);
        setEmailFilled(true);
        setNameFilled(true);
        setSendSuccess(false);
      },4000);
    } else if (sendFail) {
      // Show fail send.
      setTimeout(() => {
        setSendFail(false);
      },4000);
    }
  }, [sendSuccess, sendFail]);
  const sendMessage = () => {
    if (submitAllowed) {
      // Send email.
      setSending(true);
      setSendAttemps(sendAttemps + 1);
      emailjs.send(service, template, {
        from_name: name,
        reply_to: email,
        phone: phone,
        message: message,
      }).then(() => {
        setSendSuccess(true);
        setSending(false);
      }, () => {
        setSendFail(true);
        setSending(false);
      });
    } else {
      if (!name || name == '') {
        setNameFilled(false);
      }
      if (!email || email == '' || !EmailValidator.validate(email)) {
        setEmailFilled(false);
      }
      if (!message || message == '') {
        setMessageFilled(false);
      }
    }
  };
  return (
    <React.Fragment>
      <Grid container justifyContent={'center'} padding={4} >
        <Grid container justifyContent={'center'} maxWidth={900}>
          <Grid item container sm={12} md={6} padding={2} spacing={2} alignContent={'start'}>
            <Grid item>
              <Typography align={'left'} variant={'h4'}>
                Contact
              </Typography>
            </Grid>
            <Grid item padding={2} >
              <Typography align={'left'} variant={'body2'} marginTop={2}>
                Thank you for considering me for your home and landscaping needs!
              </Typography>
              <Typography align={'left'} variant={'body2'} marginTop={2}>
                I'm here to help bring your vision to life—whether it's transforming your outdoor space or enhancing your home's comfort.
              </Typography>
              <Typography align={'left'} variant={'body2'} marginTop={2}>
                Please fill out the form to let me know how I can assist you.
              </Typography>
              <Typography align={'left'} variant={'body2'} marginTop={2}>
                Or give me a call! <Link target="_blank" underline={'hover'} href={`tel:+1${phoneNumber}`}>{phoneNumberViewable}</Link>
              </Typography>
              <Typography align={'left'} variant={'body2'} marginTop={2}>
                I look forward to connecting with you soon!
              </Typography>
            </Grid>
          </Grid>
          <Grid item container sm={12} md={6} minHeight={400} padding={2} spacing={1.5}>
            <Grid item xs={12}>
              <TextField id="outlined-basic" color={nameFilled ? undefined : 'warning'} focused={!nameFilled} helperText={nameFilled ? '' : 'Please provide your name'} value={name} onChange={props => setName(props.target.value)} fullWidth label="Name" required variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" color={emailFilled ? undefined : 'warning'} focused={!emailFilled} helperText={emailFilled ? '' : 'Please provide a valid email'} value={email} type={'email'} onChange={props => setEmail(props.target.value)} fullWidth label="Email" required variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" value={phone} type={'tel'} onChange={props => setPhone(props.target.value)} fullWidth label="Phone" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" color={messageFilled ? undefined : 'warning'} focused={!messageFilled} helperText={messageFilled ? '' : 'Please provide a message'} value={message} onChange={props => setMessage(props.target.value)} fullWidth label="Message" required multiline rows={7} variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton loading={sending} fullWidth onClick={sendMessage} variant={'contained'}>{sending ? 'Sending' : 'Send'}</LoadingButton>
            </Grid>
            <Grid item xs={11}>
              {sendFail && <Alert severity="warning">There was an issue sending your message, please try again.</Alert>}
              {sendSuccess && <Alert severity="success">Thank you for your message. I will be in touch within 2-3 business days.</Alert>}
            </Grid>
          </Grid>
        </Grid> 
      </Grid>
    </React.Fragment>
  );
}

export default Inquiry;
